// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerija-jsx": () => import("./../src/pages/galerija.jsx" /* webpackChunkName: "component---src-pages-galerija-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-menu-de-jsx": () => import("./../src/pages/menu-de.jsx" /* webpackChunkName: "component---src-pages-menu-de-jsx" */),
  "component---src-pages-menu-en-jsx": () => import("./../src/pages/menu-en.jsx" /* webpackChunkName: "component---src-pages-menu-en-jsx" */),
  "component---src-pages-menu-fr-jsx": () => import("./../src/pages/menu-fr.jsx" /* webpackChunkName: "component---src-pages-menu-fr-jsx" */),
  "component---src-pages-menu-it-jsx": () => import("./../src/pages/menu-it.jsx" /* webpackChunkName: "component---src-pages-menu-it-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-menu-rus-jsx": () => import("./../src/pages/menu-rus.jsx" /* webpackChunkName: "component---src-pages-menu-rus-jsx" */)
}

